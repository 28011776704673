import { render, staticRenderFns } from "./listBookingGen.vue?vue&type=template&id=b6e2d5d0&scoped=true&"
import script from "./listBookingGen.vue?vue&type=script&lang=js&"
export * from "./listBookingGen.vue?vue&type=script&lang=js&"
import style0 from "./listBookingGen.vue?vue&type=style&index=0&id=b6e2d5d0&scoped=true&lang=css&"
import style1 from "./listBookingGen.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6e2d5d0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VDatePicker,VImg,VMenu,VRow,VTextField})
