<template>
    <div class="cont-settings cont-table" style="max-width:90vw">

        <div class="spin" style="z-index:100;display:none;">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="Absolute-Center-Set">

            <div class="containerIcons  ">
                <form id="formSettings">
                    <ion-list style="position:relative">
                        <ion-item>
                            <ion-label>Email Gestore</ion-label>
                            <ion-checkbox name="emailgestore" slot="start" value="emailgestore"
                                :checked="settings.emailgestore.toString().length > 0"
                                v-model="settings.emailgestore"></ion-checkbox>

                            <span class="enable-groups"
                                style="z-index: 99999;cursor: pointer;position: absolute;left: 86px"><img
                                    src="https://app.smart-book.it/_lib/img/icons8-gruppi-di-utenti-80.png"
                                    style="width:20px" title="Gruppi Abilitati" /></span>

                        </ion-item>

                        
                        <ion-item>
                            <ion-label>Email Prenotante</ion-label>
                            <ion-checkbox name="emailprenotante" slot="start" value="emailprenotante"
                                :checked="settings.emailprenotante.toString().length > 0" enabled
                                v-model="settings.emailprenotante"></ion-checkbox>
                        </ion-item>

                        <ion-item>
                            <ion-label>Company Logo</ion-label>
                            <ion-checkbox name="companylogo" slot="start" value="companylogo" enabled
                                :checked="settings.companylogo.toString().length > 0"
                                v-model="settings.companylogo"></ion-checkbox>
                        </ion-item>

                        <div v-if="set != 'RSA'" class="rsa">
                            <ion-item>

                                <ion-checkbox name="msgblockbookingCheck"
                                    :checked="settings.msgblockbooking != null && settings.msgblockbooking.toString().length > 0"
                                    slot="start" value="msgblockbookingCheck" enabled
                                    v-model="settings.msgblockbooking"></ion-checkbox>

                                <ion-textarea v-model="settings.msgblockbooking" id="msgblockbooking" name="msgblockbooking"
                                    rows="4" cols="20" placeholder="Messaggio per blocco prenotazioni."></ion-textarea>
                            </ion-item>
                        </div>

                        <div v-if="set == 'RSA'" class="rsa">
                            <ion-item>
                                <ion-label>Max num. prenot. per settimana </ion-label>
                                <ion-checkbox :checked="settings.maxbookingperweek.toString().length > 0"
                                    name="maxbookingperweekCheck" slot="start" value="maxbookingperweekCheck" enabled
                                    v-model="settings.maxbookingperweek"></ion-checkbox>
                                <ion-input style="margin-left:4px;color:#fab630;font-weight:bold;" type="number"
                                    placeholder="write here" id="maxbookingperweek" name="maxbookingperweek"
                                    :value="settings.maxbookingperweek"></ion-input>

                            </ion-item>

                            <ion-item>
                                <ion-label>Max num. gg per prenot. da oggi </ion-label>
                                <ion-checkbox name="maxdaysaftertodaytobookCheck"
                                    :checked="settings.maxdaysaftertodaytobook.toString().length > 0" slot="start"
                                    value="maxdaysaftertodaytobookCheck" enabled
                                    v-model="settings.maxdaysaftertodaytobook"></ion-checkbox>
                                <ion-input style="margin-left:4px;color:#fab630;font-weight:bold;" type="number"
                                    placeholder="write here" id="maxdaysaftertodaytobook" name="maxdaysaftertodaytobook"
                                    :value="settings.maxdaysaftertodaytobook"></ion-input>

                            </ion-item>

                            <ion-item>
                                <ion-label>Min. ore per disdire </ion-label>
                                <ion-checkbox name="minhourneedebeforecancancelCheck"
                                    :checked="settings.minhourneedebeforecancancel.toString().length > 0" slot="start"
                                    value="minhourneedebeforecancancelCheck" enabled
                                    v-model="settings.minhourneedebeforecancancel"></ion-checkbox>
                                <ion-input style="margin-left:4px;color:#fab630;font-weight:bold;" type="number"
                                    placeholder="write here" id="minhourneedebeforecancancel"
                                    name="minhourneedebeforecancancel"
                                    :value="settings.minhourneedebeforecancancel"></ion-input>

                            </ion-item>

                            <ion-item>
                                <ion-label> Min. giorni per prenotare </ion-label>
                                <ion-checkbox name="mindaystobookCheck" slot="start"
                                    :checked="settings.mindaystobook.toString().length > 0" value="mindaystobookCheck"
                                    enabled v-model="settings.mindaystobook"></ion-checkbox>
                                <ion-input style="margin-left:4px;color:#fab630;font-weight:bold;" type="number"
                                    placeholder="write here" id="mindaystobook" name="mindaystobook"
                                    :value="settings.mindaystobook"></ion-input>

                            </ion-item>
                        </div>

                        <ion-item>

                            <ion-checkbox name="companylogoCheck" :checked="settings.companyName.toString().length > 0"
                                slot="start" value="companylogoCheck" enabled></ion-checkbox>
                            <ion-input placeholder="Company name" id="companyName" name="companyName"
                                :value="settings.companyName"></ion-input>

                        </ion-item>

                        <ion-item>
                            <ion-checkbox
                                :checked="settings.backgroundImage != null && settings.backgroundImage.toString().length > 0"
                                name="inputfilelogoCheck" slot="start" value="inputfilelogoCheck" enabled
                                v-model="settings.backgroundImage" id="checkBackground"></ion-checkbox>
                            <span class="files">
                                <input type="file" id="input-file-logo" name="logo" accept=".png,.jpg,.jpeg" class="dropify"
                                    data-max-width="6610" data-max-height="6200" />
                            </span>

                        </ion-item>

                        <ion-item>

                            <ion-checkbox name="opacityCheck" slot="start" value="opacityCheck" enabled></ion-checkbox>

                            <ion-range min="1" max="10" step="1" snaps="true" pin="true" name="bckOpacity"
                                :value="settings.bckOpacity * 10" id="opacity">
                                <ion-icon size="small" slot="start" name="sunny"></ion-icon>
                                <ion-icon slot="end" name="sunny"></ion-icon>
                            </ion-range>

                            <input type="hidden" name="cid" id="cid" />
                            <input type="hidden" name="username" id="username" />

                        </ion-item>

                    </ion-list>

                </form>

            </div>
        </div>

        <!-- {{--Ajax Medium Modal--}} -->
        <div class="modal fade" id="exampleModalAlert" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true" style="min-width:100vw;position:fixed!important;top: 92px;
    display: none;
    margin: 0px auto;
    border: none;
    padding: 0;
    box-shadow: none;
    background:none;
    max-width:400px!important;max-height:700px!important">
            <div class="modal-dialog" role="document" style="min-width:180px;max-width:400px">
                <div class="modal-content">
                    <div class="modal-header" style="padding:3px 3px 3px 3px!important">
                        <h5 class="modal-title title-table" id="exampleModalLabel" style="padding-top:7px">Modal title</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="display:none">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div
                        style="width:100%;height:30px;background-color:#96959b!important;background-image: linear-gradient(to right,#e0dee6 , #96959b);font-weight:bold;padding-left:5px;padding-top:5px">
                        Gruppi abilitati alla ricezione mail</div>

                    <div class="tableads modal-body" style="max-height:200px;overflow-y: auto;min-height: 200px;">

                    </div>

                    <div class="modal-footer">

                        <img style="margin:0 auto;cursor: pointer;width:28px" class="close-condition-alert"
                            src='https://app.smart-book.it/_lib/img/cancel_32.png' />

                    </div>
                </div>
            </div>
        </div><!--  Ajax Medium Modal Ends  -->

    </div>
</template>

<script>
//import apibookingrsa from "../utils/bookingrsa/apibookingrsa";

import {
    bus
} from "../main";
import router from ".././router";
//import $ from 'jquery';

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
        var settings = JSON.stringify([Object.assign({}, this.settings)]);
        console.log("settingsPROVA", settings);
        this.loginAction();
        // window.$cookies.set("settSmartbook", settings, "9y");

    },

    mounted: function () {
        /*eslint-disable no-undef*/

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();


        var settSmartbook = JSON.parse(window.$cookies.get("settSmartbook"));

        console.log("settSmartbook", settSmartbook[0]);

        var settings = settSmartbook[0];
        this.settings = settings;

        window.cid = settings.azienda_id;

        var set = window.$cookies.get("sector");
        this.set = set;

        var b = window.$cookies.get("b");
        var $rotta = "";
        console.log($rotta, b);

        var username = window.$cookies.get("username");
        window.username = username;

        /*   if (b == "S" || b == 'Y') {

          } else {

          } */
        var that = this;
        $(document).ready(
            function () {
                /*eslint-disable no-undef*/
                $("body").off();

                $("#cid").val(window.cid);
                $("#username").val(window.username);

                var endpointUpload = that.getUrlDomain("manageBackgroundUploadApi");
                var endpointRemove = that.getUrlDomain("manageBackgroundRemoveApi");


                function upload() {

                    that.settings.backgroundImage = window.cid;

                    $("#checkBackground").attr("checked", true);

                    //var url = "{{ route('smartbook.manageBackgroundUpload') }}";
                    var url = endpointUpload;
                    console.log(url);

                    var fd = new FormData();
                    var files = $('#input-file-logo')[0].files[0];
                    fd.append('logo', files);
                    fd.append('cid', window.cid);
                    fd.append('username', window.username);

                    $.ajax({
                        url: url,
                        container: '#formSettings',

                        data: fd /* $('#formSettings').serialize() */,

                        type: 'post',

                        contentType: false,
                        processData: false,

                        success: function (data) {
                            console.log(data);
                        }
                    });

                }
                console.log(upload);

                //start
                //Setup image logo

                setTimeout(function () {

                    var oggDrop = $('.dropify').dropify({
                        messages: {
                            default: 'Drag',
                            replace: /* s */ '',
                            remove: 'Rimuovi',
                            error: 'File troppo grande'
                        }
                    });


                    $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                    $(".dropify-render").append("<img>");

                    var backgroundImage = that.settings.backgroundImage;

                    if (backgroundImage) {

                        //var txt_image = "https://app.smart-book.it/public/bck_companies/" + backgroundImage;

                        var txt_image = that.getUrlDomain("public/bck_companies/" + backgroundImage);

                        //  alert (txt_image);
                        // "{{url("/public/bck_companies") . "/" .  $fields->backgroundImage  }}"    ;

                        $(".dropify-render img").attr("src", txt_image);
                        $(".dropify-wrapper").addClass("has-preview");
                        $(".dropify-preview").show("slow");

                    }

                    /*      oggDrop.on('dropify.beforeClear', function (event, element) {
     
                             var resp = false;
                             console.log ( event, element,resp );
     
     
                             that.$swal
                                 .fire({
                                     title: "Elimina",
                                     html: "Vuoi eliminare l'elemento selezionato ?",
                                     icon: "warning",
                                     showCancelButton: true,
                                     confirmButtonColor: "#3085d6",
                                     cancelButtonColor: "#d33",
                                     confirmButtonText: "Ok",
                                 })
                                 .then(async function (result) {
     
                                     if (result.value == true) {
                                         return true;
                                     }
                                     else
                                     {
                                         return false;
                                     }
     
     
     
                                 });
     
                         }); */

                    oggDrop.on('dropify.afterClear', function (event, element) {

                        console.log(event, element);
                        var url = endpointRemove; // "{{ route('smartbook.manageBackgroundRemove') }}";

                        $.ajax({
                            url: url,
                            data: {
                                cid: window.cid,
                                username: window.username
                            }

                        });
                    });

                }, 100);

                $('#input-file-logo').on('change', function () {
                    upload();
                });

                //END

                //all events
                var endpointupdatapp = that.getUrlDomain("updateSettingsAppApi");

                var username = window.$cookies.get("username");

                // var cid = window.$cookies.get("cid");

                $("body").on("ionBlur", "ion-input , ion-textarea", function (opt) {

                    console.log(opt);
                    var valore = this.value;
                    console.log(valore);

                    $.ajax({

                        url: endpointupdatapp,
                        data: {
                            "field": this.name,
                            "checked": valore,
                            "username": username,
                            "cid": window.cid,
                        },
                        success: function (data) {
                            console.log(data);
                        }
                    });

                });

                $('body').on('click', '.close-condition-alert', function () {
                    $(".close").trigger("click");
                });

                $("body").on("ionChange", "#opacity", function (opt) {
                    console.log(opt);
                    var sett = $("#opacity").val() / 10;
                    $(".bkc_over").css("opacity", sett);
                    $.ajax({
                        url: endpointupdatapp,
                        data: {
                            "field": this.name,
                            "checked": sett,
                            "username": username,
                            "cid": window.cid,
                        },
                        success: function (data) {
                            console.log(data);

                        }
                    });
                });

                $("body").on("click", ".enable-groups", function (opt) {
                    console.log(opt);

                    $(".spin").show();
                    $.ajax({
                        url: that.getUrlDomain("getGroupsForEnableApi"),
                        data: {
                            "username": username,
                            "cid": window.cid,

                        },
                        success: function (data) {
                            var res = data.Result;
                            var errore = data.Errore;
                            var groups = data.Groups;
                            console.log(errore);
                            if (res == "OK") {
                                $('.modal-title').text('Gruppi abilitati alla ricezione mail');
                                var html_table = "<ion-list>";
                                $.each(groups, function (i, item) {
                                    html_table = html_table + "<ion-item>";
                                    html_table = html_table + "<ion-label>" + item.name + "</ion-label>";
                                    html_table = html_table + "<ion-checkbox name='" + item.name + "' slot='start' value='gruppo_" + item.idresources_group_name + "' " + item.check + "></ion-checkbox>";
                                    html_table = html_table + "</ion-item>";

                                });
                                html_table = html_table + "</ion-list>";
                                $(".tableads").html(html_table);
                                $("#exampleModalAlert").modal();

                                $(".spin").hide();
                            }
                        }
                    });
                });

                $("body").on("ionChange", "ion-checkbox", function (opt) {

                    var valore = this.checked;

                    var prefix = this.value.substring(0, 7);

                    console.log(prefix, valore, opt);
                    //console.log(prefix);

                    if (prefix != "gruppo_") {

                        if (this.value == 'opacityCheck') {
                            if (valore == false)
                                $("#opacity").val(1);
                        }

                        if (this.value == 'maxdaysaftertodaytobookCheck') {

                            if (valore == false) {
                                $("#maxdaysaftertodaytobook").val("").trigger("ionBlur");
                            }
                            return false;
                            //   $("#maxdaysaftertodaytobook").val("");

                        }

                        if (this.value == 'msgblockbookingCheck') {

                            if (valore == false) {
                                $("#msgblockbooking").val("").trigger("ionBlur");
                            }
                            return false;
                            //  $("#msgblockbooking").val("");

                        }

                        if (this.value == 'companylogoCheck') {

                            if (valore == false) {
                                $("#companyName").val("").trigger("ionBlur");
                            }
                            return false;
                            //   $("#companyName").val("");

                        }

                        if (this.value == 'inputfilelogoCheck') {

                            if (valore == false) {


                                that.$swal
                                    .fire({
                                        title: "Elimina",
                                        html: "Vuoi eliminare il background ?",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Ok",
                                    })
                                    .then(async function (result) {

                                        if (result.value == true) {
                                            //return true;
                                            $(".dropify-clear").trigger("click");
                                        }
                                        else {
                                            //return false;
                                            $("#checkBackground").attr("checked", true);

                                        }



                                    });







                            }
                            return false;

                        }

                        var val_sel = this.value;

                        console.log(val_sel);

                        $.ajax({

                            url: endpointupdatapp,
                            data: {
                                "field": this.value,
                                "checked": valore,
                                "username": username,
                                "cid": window.cid,
                            },
                            success: function (data) {

                                console.log(data);

                            }
                        });

                    }

                    if (prefix == "gruppo_") {

                        $.ajax({

                            url: that.getUrlDomain("updateGroupsForEnableApi"),
                            data: {
                                "username": username,
                                "cid": window.cid,

                            },
                            success: function (data) {
                                console.log(data);
                            }
                        });
                    }
                });

            }
        );

        $(document).ready(function () {
            $(".spin").hide();
        });

        setTimeout(() => {
            this.syncProfile();
        }, 100);
        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_qrcode":
                    router.push({
                        path: "/qrconfirm"
                    });
                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_info":
                    router.push({
                        path: "/info"
                    });
                    break;

                case "btn_pdf":

                    this.createPdf();

                    break;

                default:
                    break;
            }
        });

        this.setupButtons();

        //  this.setupGrid();

    },
    data: () => ({
        showPicker: false,
        settings: {
            "id": 10,
            "azienda_id": 3165,
            "max_reservations": 100,
            "option1": 100,
            "option2": null,
            "option3": null,
            "companyName": "",
            "created_at": "",
            "updated_at": null,
            "emailgestore": 0,
            "emailprenotante": 0,
            "companylogo": 0,
            "backgroundImage": "",
            "placement": null,
            "bckOpacity": "0.1",
            "maxbookingperweek": "",
            "minhourneedebeforecancancel": "",
            "mindaystobook": "",
            "animationseconds": 0,
            "msgblockbooking": "",
            "maxdaysaftertodaytobook": ""
        },
        set: null

    }),

    methods: {

        getUrlLoginApi: function () {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/utility/checkLogin.php";

        },


        loginAction: async function () {
            //const auth = { user: this.username, password: this.password };
            // Correct username is 'foo' and password is 'bar'
            const url = this.getUrlLoginApi();

            this.success = false;
            this.error = null;

            let formData = new FormData();

            //  var pointerVue = this;
            var pass = atob(window.$cookies.get("token_system"));

            formData.append("user", window.$cookies.get("username"));
            formData.append("password", pass);

            //formData.append("password", this.input.password);
            var login = window.$cookies.get("username");
            try {
                await this.axios
                    .post(url, formData, {
                        "content-type": "application/json"
                    })
                    .then((result) => {
                        var respo = result.data;
                        this.success = true;
                        // console.log("RESPO: ", respo   );

                        if (respo.Result == "OK") {

                            setTimeout(() => {

                                this.showSpinner = false;
                                //this.$swal("You are logged in!!!");

                                var logo = respo.logo;

                                var set = respo.settSmartbook;

                                this.$root.$children[0].settingsUser = set[0];
                                console.log("this.$root.$children[0].settingsUse", this.$root.$children[0].settingsUser);

                                if (logo.length > 0) {
                                    this.$root.$children[0].logo = logo;
                                    localStorage.setItem('logo', logo);

                                } else {
                                    localStorage.setItem('logo', "");
                                }

                                window.$cookies.set("settSmartbook", JSON.stringify(respo.settSmartbook), "9y");

                                var uname = login;
                                const args = [uname, true, ['bar', 5], {
                                    foo: 'baz'
                                }];
                                try {
                                    console.log("entering user");
                                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);

                                } catch (error) {
                                    // alert(error);
                                }

                            }, 1);


                        }
                        else {

                            this.success = false;
                            this.$swal({
                                icon: "error",
                                text: respo.ErrDetails,
                                confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_smartbook.png' />",
                                cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_smartbook.png' />",
                                showCancelButton: false,
                                customClass: {
                                    cancelButton: 'order-1',
                                    confirmButton: 'order-2',
                                }
                            });

                            this.showSpinner = false;

                        }




                    });
            } catch (err) {
                this.success = false;
                console.log(err.message);
                /* this.$swal({
                    icon: "error",
                    text: "Wrong credentials"
                }); */

                this.showSpinner = false;
                //  console.log(   response);

                //   this.error = err.message;
            }
        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                /*    var pulsantis = [

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;
 */
                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    }
}
</script>

<style>
.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}



.linkApp {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

span.title {
    color: black;
}

.contscreen {
    min-height: 64vh;
    min-width: 100%;

    display: flex;
    align-items: center;

}

ion-list {
    min-width: 300px;
}

.containerIcons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    margin: 0 auto;

}

.containerIcons {
    max-width: 460px;

    max-width: 400px;
    margin: 0 auto;
    overflow: auto;
    max-height: 80vh;

}

.riqImage {
    text-align: center;
    margin: 0 auto;
    padding: 26px;
    display: flex;
    justify-content: center;

}

@media (min-width:961px) {
    .containerIcons {
        max-width: 460px;

    }
}

.Absolute-Center-Set {

    margin: 0 auto;
    min-height: 90vh;

}

.logo {
    text-align: center;
}

.editButtons {
    display: none;
}

.dropify-wrapper {
    height: 236px !important;
    width: 236px !important;
    margin-bottom: 20px;

}

.cont-settings ion-checkbox {
    margin-right: 20px;
}

.cont-settings ion-item {
    font-size: 10px !important;
    transform: translateX(0px);
}

h5#exampleModalLabel {
    display: none;
}
</style>
