import axios from 'axios'


class APIConfigurazioneOrari {


  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  getUrlDomainGo(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = "http://localhost:4006/";
    }

    if (location.hostname != "localhost") {

      result = "https://filestransfer.it:4006/";

    }


    return result + nome_metodo;

  }


  async getTipologiePrenotazione(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("getTipologiePrenotazione"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getMedici(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("getMedici"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  // async insertConfOrario(conf_orario, tipologia, user)
  // {

  //   let formData = new FormData();

  //   formData.append("tipologia",  tipologia);
  //   formData.append("username",  user);

  //   for ( var key in conf_orario ) {
  //      formData.append(key, conf_orario[key]);
  //   }

  //   return axios.post(this.getUrlDomain("insConfOrarioApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  // }

  async insertConfOrario(conf_orario, tipologia, user) {

    let formData = new FormData();

    formData.append("tipologia", tipologia);
    formData.append("username", user);

    for (var key in conf_orario) {
      formData.append(key, conf_orario[key]);
    }

    return axios.post(this.getUrlDomainGo("storeSettingsRsa"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  // async getConfOrario(id) {


  //   return axios.get(this.getUrlDomain("getConfOrarioApi") + "/" + id + "?api=1", { 'Content-Type': 'multipart/form-data' });

  // }

  async getConfOrario(id) {

    let formData = new FormData();

    formData.append("id", id);

    return axios.post(this.getUrlDomainGo("editSettingsRsa"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  // async updateConfOrario(conf_orario, tipologia, user, id) {

  //   console.log("TIPOLOGIA: ", tipologia);

  //   let formData = new FormData();

  //   formData.append("tipologia", tipologia);
  //   formData.append("username", user);

  //   for (var key in conf_orario) {
  //     formData.append(key, conf_orario[key]);
  //   }

  //   return axios.post(this.getUrlDomain("updConfOrarioApi") + "/" + id, formData, { 'Content-Type': 'multipart/form-data' });

  // }


  async updateConfOrario(conf_orario, tipologia, user, id) {


    let formData = new FormData();

    formData.append("tipologia", tipologia);
    formData.append("username", user);
    formData.append("v_id", id);

    for (var key in conf_orario) {
      formData.append(key, conf_orario[key]);
    }

    return axios.post(this.getUrlDomainGo("updateSettingsRsa"), formData, { 'Content-Type': 'multipart/form-data' });

  }




  // async deleteConfOrario(id) {


  //   return axios.get(this.getUrlDomain("delConfOrarioApi") + "?id=" + id, { 'Content-Type': 'multipart/form-data' });

  // }


  async deleteConfOrario(id) {

    let formData = new FormData();

    formData.append("id", id);


    return axios.post(this.getUrlDomainGo("deleteSettingsRsa"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async updMultiConfOrario(id, val_ora, val_desc) {

    let formData = new FormData();

    formData.append("id", id);
    formData.append("val_ora", val_ora);
    formData.append("val_desc", val_desc);


    return axios.post(this.getUrlDomain("updMultiConfOrarioApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }





}

export default new APIConfigurazioneOrari({
  url: "http://emtool.local/public/api/smartbook/"
})
