<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_tickets cont_manage_prenot">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:0px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <input type="checkbox" name='emailgestoreall' class='checkallprenot' />
                                            </div>
                                        </th>

                                        <th class='all'>
                                            <div class='cl_num' style="color:white!important">N.</div>
                                        </th>

                                        <th class='all' style="color:white!important">Nominativo</th>
                                        <th class='all' style="color:white!important">Tipo</th>
                                        <th class='all'>
                                            <div style=" width: 100px;

text-align: right;color:white!important">Ora / Data</div>
                                        </th>
                                        <th class='all'
                                            style="text-align: right;padding-right: 7px!important;color:white!important">
                                            Persone
                                        </th>
                                        <th class='all'
                                            style="text-align: right;padding-right: 7px!important;color:white!important">
                                            Stato
                                        </th>
                                        <th class='all' style="color:white!important">E-Mail</th>
                                        <th class='all'>
                                            <div style=" width: 80px;

text-align: right;color:white!important">Telefono</div>
                                        </th>
                                        <th class='all' style="color:white!important">Note</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="10">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/smartbook_ricerca3.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_smartbook.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-confirm-search" contain
                                    src="@/assets/btn_confirm3_smartbook.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupedit" style="display:none">

            <template>

                <modal name="popupEdit" :clickToClose="false" :width="300" :height="214">

                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-bottom: 0px!important;">

                            <div style="border-left: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:240px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>

                    <v-row class="rowGestPrenot" style="margin-top:-12px!important">

                        <v-col cols="12" md="12" style="display:flex">

                            <div style="color:white;margin-left:26px;font-size:12px">
                                Modifica Prenotazione
                            </div>


                        </v-col>

                    </v-row>

                    <v-row class="rowGestPrenot" style="margin-top:-12px!important">

                        <v-col cols="12" md="12">

                            <div class="form-group"
                                style="padding-left: 18px;padding-right: 18px;background-color: black;">


                                <label style="color:white;margin-left: 10px;"><b>Numero persone</b></label>
                                <div class="form-rows" style="margin-bottom: 3px!important;">
                                    <div class="col-md-12" style="display: flex;margin-top:-16px!important">
                                        <input min="1" max="100" type="number" id="num_persons_edit"
                                            name="num_persons_edit" class="form-control" />

                                    </div>
                                    <div id="suggesstion-box" class="errorMessageNumPersoneEdit"></div>

                                </div>


                            </div>


                        </v-col>

                    </v-row>

                    <v-row style="margin-top: 54px!important;background-color: black!important;">

                        <v-col cols="12" md="12" style="display: flex;justify-content: center;">

                            <v-img alt="" class="btn-cancel-edit" contain
                                src="@/assets/btn_cancel3_smartbook_ins_book.png" max-width="30" max-height="30"
                                style="cursor:pointer" transition="scale-transition" title="Annulla"
                                @click="btnCancelEdit" />


                            <v-img alt="" class="btn-confirm-edit" contain
                                src="@/assets/btn_confirm3_smartbook_ins_book.png" max-width="30" max-height="30"
                                style="cursor:pointer" transition="scale-transition" title="Conferma"
                                @click="btnConfirmEdit" />

                        </v-col>

                    </v-row>

                    <v-row style="margin-bottom: 0px!important;margin-top: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex">


                            <div style="border-left: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:240px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>


                </modal>


            </template>


        </div>



        <div class="contpopupconfupd" style="display:none">

            <template>
                <modal name="popupConfUpd" :clickToClose="false" :width="462" :height="95">


                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-bottom: 0px!important;">

                            <div style="border-left: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:402px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>



                    <v-row class="rowGestPrenot" style="margin-top:-12px!important">

                        <v-col cols="12" md="12"
                            style="text-align: center;color:white;font-size: 16px;font-weight: bold;">

                            Prenotazione aggiornata correttamente

                        </v-col>

                    </v-row>

                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-top: -12px!important;">


                            <div style="border-left: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:402px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>


                </modal>
            </template>

        </div>

        <div class="contpopupconfdelete" style="display:none">

            <template>
                <modal name="popupConfDelete" :clickToClose="false" :width="462" :height="149">


                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-bottom: 0px!important;">

                            <div style="border-left: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:402px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>



                    <v-row class="rowGestPrenot" style="margin-top:-12px!important">

                        <v-col cols="12" md="12"
                            style="text-align: center;color:white;font-size: 16px;font-weight: bold;">

                            Vuoi disdire la prenotazione ?

                        </v-col>

                    </v-row>

                    <v-row style="background-color: black!important;">

                        <v-col cols="12" md="12" style="display: flex;justify-content: center;">

                            <v-img alt="" class="btn-cancel-delete" contain
                                src="@/assets/btn_cancel3_smartbook_ins_book.png" max-width="30" max-height="30"
                                style="cursor:pointer" transition="scale-transition" title="Annulla"
                                @click="btnCancelDelete" />


                            <v-img alt="" class="btn-confirm-delete" contain
                                src="@/assets/btn_confirm3_smartbook_ins_book.png" max-width="30" max-height="30"
                                style="cursor:pointer" transition="scale-transition" title="Conferma"
                                @click="btnConfirmDelete" />

                        </v-col>

                    </v-row>

                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-top: -12px!important;">


                            <div style="border-left: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:402px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>


                </modal>
            </template>

        </div>


        <div class="contpopupdeleteok" style="display:none">

            <template>
                <modal name="popupDeleteOk" :clickToClose="false" :width="462" :height="95">


                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-bottom: 0px!important;">

                            <div style="border-left: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:402px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>



                    <v-row class="rowGestPrenot" style="margin-top:-12px!important">

                        <v-col cols="12" md="12"
                            style="text-align: center;color:white;font-size: 16px;font-weight: bold;">

                            Prenotazione disdetta correttamente

                        </v-col>

                    </v-row>

                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-top: -12px!important;">


                            <div style="border-left: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:402px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>


                </modal>
            </template>

        </div>




        <div class="contpopupconfupdmobile" style="display:none">

            <template>
                <modal name="popupConfUpdMobile" :clickToClose="false" :width="363" :height="95">


                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-bottom: 0px!important;">

                            <div style="border-left: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:402px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>



                    <v-row class="rowGestPrenot" style="margin-top:-12px!important">

                        <v-col cols="12" md="12"
                            style="text-align: center;color:white;font-size: 16px;font-weight: bold;">

                            Prenotazione aggiornata correttamente

                        </v-col>

                    </v-row>

                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-top: -12px!important;">


                            <div style="border-left: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:402px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>


                </modal>
            </template>

        </div>


        <div class="contpopupconfdeletemobile" style="display:none">

            <template>
                <modal name="popupConfDeleteMobile" :clickToClose="false" :width="363" :height="149">


                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-bottom: 0px!important;">

                            <div style="border-left: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:402px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>



                    <v-row class="rowGestPrenot" style="margin-top:-12px!important">

                        <v-col cols="12" md="12"
                            style="text-align: center;color:white;font-size: 16px;font-weight: bold;">

                            Vuoi disdire la prenotazione ?

                        </v-col>

                    </v-row>

                    <v-row style="background-color: black!important;">

                        <v-col cols="12" md="12" style="display: flex;justify-content: center;">

                            <v-img alt="" class="btn-cancel-delete-mobile" contain
                                src="@/assets/btn_cancel3_smartbook_ins_book.png" max-width="30" max-height="30"
                                style="cursor:pointer" transition="scale-transition" title="Annulla"
                                @click="btnCancelDelete" />


                            <v-img alt="" class="btn-confirm-confirm-mobile" contain
                                src="@/assets/btn_confirm3_smartbook_ins_book.png" max-width="30" max-height="30"
                                style="cursor:pointer" transition="scale-transition" title="Conferma"
                                @click="btnConfirmDelete" />

                        </v-col>

                    </v-row>

                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-top: -12px!important;">


                            <div style="border-left: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:402px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>


                </modal>
            </template>

        </div>

        <div class="contpopupdeleteokmobile" style="display:none">

            <template>
                <modal name="popupDeleteOkMobile" :clickToClose="false" :width="363" :height="95">


                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-bottom: 0px!important;">

                            <div style="border-left: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:402px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>



                    <v-row class="rowGestPrenot" style="margin-top:-12px!important">

                        <v-col cols="12" md="12"
                            style="text-align: center;color:white;font-size: 16px;font-weight: bold;">

                            Prenotazione disdetta correttamente

                        </v-col>

                    </v-row>

                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-top: -12px!important;">


                            <div style="border-left: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:402px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>


                </modal>
            </template>

        </div>


    </div>
</template>

<script>
import apibookingrsa from "../utils/bookingrsa/apibookingrsa";
import apibookinggen from "../utils/bookinggen/apibookinggen";

import {
    bus
} from "../main";
//import router from ".././router";
//import $ from 'jquery';

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {

        /*eslint-disable no-undef*/

        $("header").css("visibility", "hidden");


        this.$root.$children[0].viewUserInfo = false;

        this.$root.$children[0].viewHome = false;


        console.log("PARAMS: ", this.$route.params.id);


        window.curIdSel = this.$route.params.id;

        this.cur_id = this.$route.params.id;





        $("body").off();

        window.curSrcText = "";


        $(document).ready(function () {

            $('body').on('click', '.checkallprenot', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row-prenot");

                    $("tr").eq(0).removeClass("active-row-prenot");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row-prenot");


                }

                pointerVue.hideShowButtonsSelRow();


            });

            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

        });



        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;

            //  var ids = "";

            console.log(data);
            switch (data) {


                case "btn_delete":


                    this.openPopupConfDelete();

                    break;


                case "btn_edit":

                    this.getInfoBooking();



                    break;

                default:
                    break;

            }
        });

        // this.setupButtons();

        this.setupButtonsGrid();

        var pointerVue = this;


        this.setupGrid();
        this.hideShowButtons(false);


        /*    setTimeout(() => {
   
               $(".v-bottom-navigation").css("background-color", "black");
               $(".v-bottom-navigation").css("border-color", "black");
   
           }, 100); */

        setTimeout(() => {

            $("#bottomNavigation").addClass("clStyleBottom");

        }, 100);


        // setTimeout(() => {


        //     pointerVue.selectRow();


        // }, 1000);


    },
    data: () => ({

        data: "",
        name: "",
        phone: "",
        //email: "",
        num_persons: "",
        note: "",



        cur_id: 0,

        textSearch: "",

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    methods: {


        selectRow: function () {

            var that = this;

            console.log("SELECT_ROW_BY_ID");

            console.log("NUM ELEMENTS: ", $(".val_status").length);


            $(".val_status").each(function (index) {

                console.log(index);

                console.log("ID: ", $(this).attr("data-id"));

                console.log("FOUND");

                // imposto il checkbox
                $(this).prop("checked", true);

                // seleziono la riga del documento
                var v_row = $(this).parent().parent();
                $(v_row).addClass("active-row-prenot");

                var item = window.table.row(v_row).data();

                console.log("ITEM ROW SEL ROW: ", item);

                that.currItemRow = item;

                that.hideShowButtonsSelRow();


            });


        },

        openPopupConfDelete: function () {

            if (window.innerWidth <= 768) {

                $(".contpopupconfdeletemobile").show();

                this.$modal.show('popupConfDeleteMobile');

            }
            else {

                $(".contpopupconfdelete").show();

                this.$modal.show('popupConfDelete');

            }



        },

        btnCancelDelete: function () {

            if (window.innerWidth <= 768) {

                this.$modal.hide('popupConfDeleteMobile');

            }
            else {

                this.$modal.hide('popupConfDelete');

            }



        },

        btnConfirmDelete: function () {

            if (window.innerWidth <= 768) {

                this.$modal.hide('popupConfDeleteMobile');

            }
            else {

                this.$modal.hide('popupConfDelete');

            }

            this.deleteRow();

        },


        validate: function () {

            var that = this;

            var errore = 0;

            $(".errorMessageNumPersoneEdit").html("&nbsp;");

            if ($("#num_persons_edit").val() == "") {
                errore = 1;
                $(".errorMessageNumPersoneEdit").html("Valore richiesto");
            }

            if (errore == 0) {
                if ($("#num_persons_edit").val() != "") {
                    if (!/^\d+$/.test($("#num_persons_edit").val())) {
                        errore = 1;
                        $(".errorMessageNumPersoneEdit").html("Valore non valido");
                    }
                }
            }

            if (errore == 0) {
                if ($("#num_persons_edit").val() != "") {

                    let number = parseInt($("#num_persons_edit").val(), 10);

                    if ((number < 1) || (number > 20)) {
                        errore = 1;
                        $(".errorMessageNumPersoneEdit").html("Il valore deve essere tra 1 e 20");

                    }

                }
            }

            if (errore == 0) {

                console.log("ok");

                that.updateBooking();



            }




        },


        getUrlPath: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + nome_metodo;

        },




        getInfoBooking: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibookinggen.getInfoPrenotazioni(
                v_token,
                that.cur_id

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getInfoPrenotazioni", res);

                that.data = res.data.booking.booking_date_time;
                that.name = res.data.booking.name;
                that.phone = res.data.booking.phone;
                that.email = res.data.booking.email;
                that.num_persons = res.data.booking.num_persons;
                that.note = res.data.booking.note;




                setTimeout(function () {


                    $(".contpopupedit").show();

                    that.$modal.show('popupEdit');

                    setTimeout(function () {

                        $(".errorMessageNumPersoneEdit").html("&nbsp;");

                        $("#num_persons_edit").val(that.num_persons);


                    }, 300);



                }, 200);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },


        btnCancelEdit: function () {

            this.$modal.hide('popupEdit');

        },

        btnConfirmEdit: function () {


            this.validate();


        },

        updateBookingOld: async function () {

            var that = this;


            var error = "";

            if ($("#num_persons_edit").val() == "") {
                error = "Specificare il numero delle persone";
            }

            if (error.length == 0) {

                that.$modal.hide('popupEdit');

                var v_token = window.$cookies.get("token");

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response = await apibookinggen.updateBooking(
                    v_token,
                    that.cur_id,
                    $("#num_persons_edit").val(),
                    that.num_persons
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from getInfoPrenotazioni", res);


                    that.$swal({
                        icon: "success",
                        text: "Prenotazione aggiornata correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });


                    setTimeout(() => {

                        window.table.ajax.reload();



                    }, 2000);

                    setTimeout(() => {

                        that.selectRow();

                    }, 1000);



                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile eliminare i dati");
                    console.log("response", response);

                }

                );


            }
            else {

                that.$swal({
                    icon: "error",
                    text: error,
                    showConfirmButton: false,
                    timer: 2000
                });


            }


        },


        updateBooking: async function () {

            var that = this;


            that.$modal.hide('popupEdit');

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibookinggen.updateBooking(
                v_token,
                that.cur_id,
                $("#num_persons_edit").val(),
                that.num_persons
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getInfoPrenotazioni", res);

                setTimeout(() => {


                    if (window.innerWidth <= 768) {

                        $(".contpopupconfupdmobile").show();

                        that.$modal.show('popupConfUpdMobile');

                    }
                    else {

                        $(".contpopupconfupd").show();

                        that.$modal.show('popupConfUpd');

                    }





                }, 200);


                setTimeout(() => {




                    if (window.innerWidth <= 768) {

                        that.$modal.hide('popupConfUpdMobile');

                    }
                    else {

                        that.$modal.hide('popupConfUpd');

                    }




                    window.table.ajax.reload();

                }, 2000);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        hideShowButtonsSelRow: function () {

            var tot = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                }

            });

            setTimeout(() => {

                var pulsantis = [];


                if (tot == 1) {

                    pulsantis.push({
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: this.getUrlPath("public/_lib/img/icon-edit-smartbook-prenot.png"),
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        size: 30,
                        title: "Modifica"
                    });

                }

                if (tot > 0) {

                    pulsantis.push({
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: this.getUrlPath("public/_lib/img/icon-delete-smartbook-prenot.png"),
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        size: 25,
                        title: "Disdici"
                    });

                }




                this.$root.$children[0].bnavbuttons = pulsantis;






            }, 100);





        },



        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },

        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            $(".spin").show();

            setTimeout(function () {

                window.table.ajax.reload();

            }, 200);


        },

        deleteRow: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            

            console.log("IDS SEL:", ids);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibookinggen.deleteBooking(
                v_token,
                window.curIdSel
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from deleteBooking", res);


                setTimeout(() => {

                    if (window.innerWidth <= 768) {

                        $(".contpopupdeleteokmobile").show();

                        that.$modal.show('popupDeleteOkMobile');

                    }
                    else {

                        $(".contpopupdeleteok").show();

                        that.$modal.show('popupDeleteOk');

                    }

                }, 200);

                setTimeout(() => {



                    if (window.innerWidth <= 768) {

                        that.$modal.hide('popupDeleteOkMobile');

                    }
                    else {

                        that.$modal.hide('popupDeleteOk');

                    }


                    var dest = "https://trattoriadapasqualino.it"

                    window.location.href = dest;

                }, 2000);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        deleteRowOld: async function () {

            var that = this;

            that.$swal
                .fire({
                    title: "Disdetta",
                    html: "Vuoi disdire la prenotazione ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_smartbook.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_smartbook.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    },
                })
                .then(async function (result) {

                    if (result.value == true) {

                        var v_token = window.$cookies.get("token");

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apibookinggen.deleteBooking(
                            v_token,
                            window.curIdSel
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteBooking", res);

                            that.$swal({
                                icon: "success",
                                text: "Prenotazione disdetta correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {


                                var dest = "https://trattoriadapasqualino.it"

                                window.location.href = dest;

                            }, 2000);


                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("ERrori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            //return result + "public/api/smartbook/" + nome_metodo;

            // alert(result + nome_metodo);

            return result + nome_metodo;

        },

        setupGrid: function () {

            // var that = this;
            //  var pathLocal = that.$withBase('/');

            var pointerVue = this;



            //var dateTest = new Date();
            //dateTest = dateTest.setDate(dateTest.getDate() + 365);
            //const dateTest2 = new Date(dateTest).toJSON().slice(0, 10);

            //console.log("DATE: " + dateTest2) //2015-07-23

            const date = new Date().toJSON().slice(0, 10)

            //console.log("DATE: " + date) //2015-07-23

            //  var $futureTime = dateTest2;

            $(document).ready(function () {

                var v_token = window.$cookies.get("token");

                //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

                // console.log("HOSTNAME: " + $(location).attr('hostname'));
                // console.log("PROTOCOL: " + $(location).attr('protocol'));

                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": 30,

                    processing: false,
                    paging: true,
                    serverSide: true,

                    ajax: {
                        url: pointerVue.getUrlDomain("getManagePrenot") + '?username=' + v_token,


                        data: function (valori) {

                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.curSrcText = window.curSrcText;

                            valori.curIdSel = window.curIdSel;

                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: [

                        {
                            data: 'sel_row',
                            orderable: false,
                            searchable: false,
                            name: '',
                            width: '1%',
                        },


                        {
                            data: 'row_id',
                            orderable: false,
                            name: 'row_id',
                            width: '1%'
                        },

                        {
                            data: 'name',
                            orderable: false,
                            name: 'name',
                            width: '13%'
                        },

                        {
                            data: 'tipo',
                            orderable: false,
                            name: 'tipo',
                            width: '5%'
                        },


                        {
                            data: 'ora',
                            orderable: false,
                            name: 'ora',
                            width: '1%',
                            render: function (data, row) {
                                console.log(data, row);

                                var html = "";

                                if (data != null) {


                                    var date = "";
                                    try {

                                        date = data.substring(0, 10);
                                    } catch (error) {
                                        console.log(error);
                                    }

                                    var hour = "";
                                    try {

                                        hour = data.split(" ")[1].slice(0, 5);
                                    } catch (error) {
                                        console.log(error);
                                    }

                                    html = "<div class='clColumnDataOra' style='color:white!important'><b>" + hour + "</b> / " + date + "</div>";
                                }



                                return html;
                            }
                        },





                        {
                            data: 'num_persons',
                            orderable: false,
                            name: 'num_persons',
                            width: '5%'
                        },

                        {
                            data: 'status',
                            orderable: false,
                            name: 'status',
                            width: '5%'
                        },

                        {
                            data: 'email',
                            orderable: false,
                            name: 'email',
                            width: '10%'
                        },

                        {
                            data: 'phone',
                            orderable: false,
                            name: 'phone',
                            width: '5%'
                        },

                        {
                            data: 'note',
                            orderable: false,
                            name: 'note',
                            width: '10%'
                        },




                    ],

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();




                        console.log("DRAWCALLBACK");


                        setTimeout(() => {


                            pointerVue.selectRow();


                        }, 1000);


                    }

                });

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);



                $("#myTable_filter").find("label").remove();


                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Gestione Prenotazione</div>

                </div>
                

            `);

                //new $.fn.dataTable.FixedHeader(window.table);

                console.log("EVENT: ", window.mediciAddedEvents);

                //if (window.mediciAddedEvents === undefined) {

                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });


                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });

                $('body').on('click', '.cont_manage_prenot tr', function () {

                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            console.log("ROW DATA: ", row_data);


                            var last_id_sel = $("tr.active-row-prenot").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");


                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row-prenot");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row-prenot");


                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row-prenot");



                            }

                            window.id_par_sel = $("tr.active-row-prenot").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);


                            console.log("FIND VAL_STATUS: ", $(this).find(".val_status"));

                            var find_check = $(this).find(".val_status");
                            if (find_check.is(":checked")) {
                                console.log("SELEZIONATO");

                            }

                            if (!find_check.is(":checked")) {
                                console.log("DESELEZIONATO");

                            }


                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);




                });


                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });
                    window.currDay = date;
                    window.currDayTo = date;



                }, 600);

                window.mediciAddedEvents = true;

            });

        },

        hideShowButtons: async function (v_enable) {
            console.log(v_enable);


            setTimeout(() => {

                var pulsantis = [];

                this.$root.$children[0].bnavbuttons = pulsantis;


            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon-add-smartbook.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        size: 30,
                        title: "Aggiungi"

                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon-edit-smartbook.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        size: 30,
                        title: "Modifica"

                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon-delete-smartbook.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        size: 25,
                        title: "Elimina"

                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },


        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

        createPdf: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            // valori.currDayTo = window.currDayTo;
            // valori.curStatus = window.curStatus;
            // valori.curPiano = window.curPiano;

            var response = await apibookingrsa.getPdfPrenotazioni(
                "ZmFnb3N0b25p",
                3165,
                window.currDay,
                window.currDayTo,
                window.curStatus,
                window.curPiano,
                ""
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPdfPrenotazioni", res);

                try {

                    console.log(res.data.Result);

                    window.open(res.data.Result, '_blank');

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile esportare il pdf");
                console.log("response", response);

            }

            );

        }

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.clColumnNamePrenot {
    color: white !important;
    font-size: 9px !important;
}

.clColumnEmailPrenot {
    color: white !important;
    font-size: 9px !important;
}

.clColumnNotePrenot {
    color: white !important;
    font-size: 9px !important;
}




.errorMessageNumPersoneEdit {
    color: red;
    font-weight: bold;
    font-size: 12px;
    margin-left: 10px;
    margin-top: -12px;
}

#num_persons_edit {
    color: white !important;
    font-size: 12px !important;
    background-color: black !important;
    border: 1px solid #c39d63 !important;
}

.rowGestPrenot {
    display: flex !important;
    height: 35px !important;
    align-items: top !important;
    background-color: black;
}

.clStyleBottom {
    background-color: black !important;
    border-color: black !important;
}

.cont_manage_prenot .dataTable tbody tr {
    background-color: black !important;
}

.cont_manage_prenot .dataTable tbody tr td {
    background-color: black !important;
}



.cont_manage_prenot .card {
    border: 0px !important;
    background-color: black !important;
}

.clColumnDataOra {
    width: 100px;
    font-size: 9px !important;
    text-align: right;
}

.clColumnTelefono {
    width: 80px;
    font-size: 9px !important;
    text-align: right;
}



.clColumnDataFont {
    font-size: 9px !important
}



.swipeAnagraficaEdit {
    padding-top: 20px !important;

    padding-bottom: 150px !important;
    overflow-x: hidden;
    overflow-y: hidden;

    max-height: 100vh;

}



.cl-date {
    font-size: 10px;
    color: gray;
}


.font-row-grid-datetime2 {
    font-size: 10px !important;
    padding: 0px !important;
    width: 100px !important;
    margin: 0px !important;
    height: 10px !important;
    margin-top: -13px !important;
}

.cont_manage_prenot .clColStatus {
    width: 60px;
    text-align: right;
    float: right;
    font-size: 9px !important;
}


.cont_manage_prenot .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_manage_prenot .val_status {
    width: 10px !important;
    accent-color: #c39d63 !important;
}

.cont_manage_prenot .checkallprenot {
    width: 10px !important;
    accent-color: #c39d63 !important;
}


.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f77002;

}

.pay a {
    color: #f77002;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #d56e1a;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}

.cont_manage_prenot #myTable {
    margin-right: 3px;
    width: 885px !important;
}

.cont_manage_prenot table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_manage_prenot table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 20px !important;
}

.cont_manage_prenot th {
    padding-left: 5px !important;
}

.cont_manage_prenot ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_manage_prenot .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}

.cont_manage_prenot #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background: black !important;
    border-color: #c39d63 !important;
    top: 0px;
}

.cont_manage_prenot div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}


.cont_manage_prenot #myTable_wrapper {
    padding-top: 0px;
    min-width: 900px;
    max-width: 900px;
    width: 900px;
}

.cont_manage_prenot .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}


.cont_manage_prenot .text_title {
    background-color: black !important;
    color: white !important;
    font-weight: bold !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 0px !important;
    font-size: 10px !important;
    min-width: 82vw !important;
}


.cont_manage_prenot ion-checkbox {
    --size: 10px !important;
    max-width: 10px !important;
    min-width: 10px !important;
}


@media screen and (max-width: 960px) {

    .cont_manage_prenot #myTable {
        /* margin-top:54px; */
    }

    .cont_manage_prenot #myTable_filter {
        top: 0px;
        position: fixed;

    }


    .cont_manage_prenot .cl_num {
        margin-left: -2px !important;
    }

    .cont_manage_prenot .cl_checkall {
        padding-left: 1px;
        padding-top: 2px;
    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }

    .cont_manage_prenot .table-responsive {
        padding: 0px;
        min-width: 100vw;
        max-width: 100vw;
        left: 0px;
        position: fixed;
    }

    .cont_manage_prenot #myTable {
        max-width: 100vw !important;
        margin-right: 0px;
        min-width: 100vw !important;
        position: relative;
        width: 100vw !important;

    }

    .cont_manage_prenot #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: auto !important;
    }

    .clColumnNamePrenot {
        color: white !important;
        font-size: 9px !important;
        width: 120px !important;
    }

    .clColumnEmailPrenot {
        color: white !important;
        font-size: 9px !important;
        width: 120px !important;
    }


    .clColumnNotePrenot {
        color: white !important;
        font-size: 9px !important;
        width: 120px !important;
    }



}
</style>
