import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIEccezioni {


  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  getUrlDomainGo(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = "http://localhost:4006/";
    }

    if (location.hostname != "localhost") {

      result = "https://filestransfer.it:4006/";

    }


    return result + nome_metodo;

  }


  // async insertEccezione(eccezione, user)
  // {

  //   let formData = new FormData();

  //   formData.append("username",  user);

  //   for ( var key in eccezione ) {
  //      formData.append(key, eccezione[key]);
  //   }

  //   return axios.post(this.getUrlDomain("insEccezioneApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  // }



  async insertEccezione(eccezione, user) {

    let formData = new FormData();

    formData.append("username", user);

    for (var key in eccezione) {
      formData.append(key, eccezione[key]);
    }

    return axios.post(this.getUrlDomainGo("storeException"), formData, { 'Content-Type': 'multipart/form-data' });

  }





  // async getEccezione(id) {

  //   return axios.get(this.getUrlDomain("getEccezioneApi") + "/" + id + "?api=1", { 'Content-Type': 'multipart/form-data' });

  // }

  async getEccezione(id) {

    let formData = new FormData();

    formData.append("id", id);

    return axios.post(this.getUrlDomainGo("editException"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  // async updateEccezione(eccezione, id, user) {

  //   let formData = new FormData();

  //   formData.append("username", user);

  //   for (var key in eccezione) {
  //     formData.append(key, eccezione[key]);
  //   }

  //   return axios.post(this.getUrlDomain("updEccezioneApi") + "/" + id, formData, { 'Content-Type': 'multipart/form-data' });

  // }

  async updateEccezione(eccezione, id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id", id);

    for (var key in eccezione) {
      formData.append(key, eccezione[key]);
    }

    return axios.post(this.getUrlDomainGo("updateException"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  // async deleteEccezione(id) {


  //   return axios.get(this.getUrlDomain("delEccezioneApi") + "?id=" + id, { 'Content-Type': 'multipart/form-data' });

  // }

  async deleteEccezione(id) {

    let formData = new FormData();

    formData.append("id", id);

    return axios.post(this.getUrlDomainGo("deleteException"), formData, { 'Content-Type': 'multipart/form-data' });

  }



}

export default new APIEccezioni({
  url: "http://emtool.local/public/api/smartbook/"
})
